@import "../../../../styles/breakpoints.scss";
@import "../../../../styles/palette.scss";

.container {
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  background-color: color(sand, sandOne);
  color: color(secondary, secondaryOne);

  @include respond-to("xl") {
    flex-direction: row;
  }

  .contentContainer {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    max-width: 1600px;
    width: 100%;
    position: relative;
    padding: 100px 25px 40px 25px;
    @include respond-to("s") {
      padding: 200px 80px 100px 80px;
    }
    @include respond-to("m") {
      justify-content: flex-start;
    }
    @include respond-to("xl") {
      padding-left: 0px;
      margin-left: 80px;
    }

    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      @include respond-to("m") {
        max-width: 75%;
      }
      .breadcrumbs {
        display: flex;
        flex-direction: row;
        gap: 6px;
        margin-bottom: 20px;
        @include respond-to("s") {
          margin-bottom: 40px;
        }
        .arrow {
          display: flex;
          margin-top: 3px;
          transform: scale(0.5);
          @include respond-to("s") {
            margin-top: 3px;
            transform: scale(0.75);
          }
        }
        .row {
          display: flex;
          flex-direction: row;
          gap: 6px;
          align-items: center;
          .item {
            .heart {
              display: flex;
              margin-top: 3px;
              transform: scale(0.5);
              @include respond-to("s") {
                margin-top: 0px;
                transform: scale(0.75);
              }
            }
            span {
              margin: 0;
              font-size: 12px;
              line-height: 14px;
              text-transform: uppercase;
              @include respond-to("s") {
                font-size: 14px;
                line-height: 16px;
              }
            }
            a {
              font-size: 12px;
              line-height: 14px;
              text-transform: uppercase;
              @include respond-to("s") {
                font-size: 14px;
                line-height: 16px;
              }
            }
          }
        }
      }
      .header {
        display: flex;
        flex-direction: column;
        gap: 20px;
        @include respond-to("s") {
          gap: 40px;
        }
        h1 {
          max-width: 905px;
          width: 100%;
          font-size: 40px;
          line-height: 44px;
          font-weight: bold;
          margin: 0;
          @include respond-to("s") {
            font-size: 72px;
            line-height: 80px;
          }
          @include respond-to("l") {
            max-width: 1200px;
          }
        }
        p {
          margin: 0;
          font-size: 20px;
          line-height: 25px;
          max-width: 905px;
          @include respond-to("s") {
            font-size: 32px;
            line-height: 40px;
          }
          @include respond-to("l") {
            max-width: 1200px;
          }
        }
      }
      .linkCol {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        @include respond-to("s") {
          margin-top: 80px;
        }
        hr {
          border: none;
          width: 100%;
          height: 3px;
          background-color: color(secondary, secondaryOne);
          margin: 0;
        }
        .linkItem {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 20px 0;
          display: flex;
          flex-direction: row;
          font-size: 16px;
          line-height: 24px;
          @include respond-to("s") {
            font-size: 22px;
            line-height: 33px;
          }
          .icon {
            display: flex;
            justify-content: center;
            @include respond-to("s") {
              margin-top: 5px;
            }
            svg {
              margin-right: 10px;
              transition: margin-right 0.3s ease-out;
            }
          }
        }
        .linkItem:hover {
          cursor: pointer;
          svg {
            margin-right: 0px;
          }
        }
      }
    }
    .btn {
      cursor: pointer;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      border-radius: 0px 0px 40px 40px;
      background-color: color(secondary, secondaryOne);
      bottom: -20px;
      right: 25px;
      z-index: 10;
      @include respond-to("s") {
        height: 80px;
        width: 80px;
        bottom: -40px;
        right: 80px;
        svg {
          transform: scale(2);
        }
      }
      svg {
        path:nth-child(2) {
          fill: color(primary, primaryOne);
        }
      }
    }
  }

  .imgWrapper {
    height: 100vw;
    width: 100vw;
    @include respond-to("m") {
      height: auto;
      width: 100%;
      aspect-ratio: 16/9;
    }
    @include respond-to("xl") {
      height: 100vh;
      width: calc(50vw);
      position: absolute;
      right: 0;
    }
    .video {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
    .img {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
}
