@import "../../../../styles/breakpoints.scss";
@import "../../../../styles/palette.scss";
.container {
  background-color: color(primary, primaryOne);
  .content {
    display: flex;
    @include respond-to("l") {
      justify-content: center;
    }
    .header {
      display: flex;
      padding: 100px 25px 40px 25px;
      @include respond-to("s") {
        padding: 200px 80px 100px 80px;
      }
      @include respond-to("l") {
        max-width: 1600px;
      }
      h1 {
        max-width: 905px;
        width: 100%;
        font-size: 40px;
        line-height: 44px;
        font-weight: 700;
        color: color(secondary, secondaryOne);
        margin: 0;
        @include respond-to("s") {
          font-size: 72px;
          line-height: 80px;
        }
        @include respond-to("l") {
          max-width: 75%;
        }
      }
    }
  }
  .imgWrapper {
    height: 100vw;
    width: 100vw;
    position: relative;
    @include respond-to("m") {
      height: 100vh;
      width: 100vw;
    }
    .video {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
    .img {
      position: absolute;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .btn {
      cursor: pointer;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      border-radius: 0px 0px 40px 40px;
      background-color: color(secondary, secondaryOne);
      top: -20px;
      right: 25px;
      z-index: 1;
      @include respond-to("s") {
        height: 80px;
        width: 80px;
        top: -40px;
        right: 80px;
        svg {
          transform: scale(2);
        }
      }
      svg {
        path:nth-child(2) {
          fill: color(primary, primaryOne);
        }
      }
    }
  }
}
