@import "../../../../styles/breakpoints.scss";
@import "../../../../styles/palette.scss";

.richText {

  img {
    max-width: 100%;
    object-fit: contain;
  }
  h2 {
    font-size: 24px;
    line-height: 40px;
    font-weight: bold;
    @include respond-to("s") {
      font-size: 40px;
      line-height: 50px;
    }
  }
  h3 {
    font-size: 20px;
    line-height: 25px;
    font-weight: bold;
    @include respond-to("s") {
      font-size: 32px;
      line-height: 40px;
    }
  }
  h4 {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    @include respond-to("s") {
      font-size: 28px;
      line-height: 35px;
    }
  }
  h5 {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    @include respond-to("s") {
      font-size: 24px;
      line-height: 30px;
    }
  }
  h6 {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    @include respond-to("s") {
      font-size: 22px;
      line-height: 28px;
    }
  }
  p {
    font-size: 16px;
    list-style: 24px;
    @include respond-to("s") {
      font-size: 22px;
      line-height: 33px;
    }
  }
  li {
    font-size: 16px;
    line-height: 24px;
    @include respond-to("s") {
      font-size: 22px;
      line-height: 33px;
    }
  }
  ul {
    li::before {
      height: 16px;
      width: 16px;
      background-size: 16px 16px;
      background-image: url("../../../../public/icons/ico-heart.svg");
      background-repeat: no-repeat;
      @include respond-to("s") {
        height: 24px;
        width: 24px;
        background-size: 24px 24px;
      }
    }
  }
  ol {
    padding: 0;
    padding-left: 30px;
    @include respond-to("s") {
      padding-left: 38px;
    }
    li::marker {
      font-weight: bold;
    }
  }
  a {
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  hr {
    border: none;
    width: 100%;
    height: 4px;
    background-color: color(secondary, secondaryOne);
    margin: 0;
  }
}

.darkRichText {

  img {
    max-width: 100%;
    object-fit: contain;
  }
  h2 {
    font-size: 24px;
    line-height: 40px;
    font-weight: bold;
    @include respond-to("s") {
      font-size: 40px;
      line-height: 50px;
    }
  }
  h3 {
    font-size: 20px;
    line-height: 25px;
    font-weight: bold;
    @include respond-to("s") {
      font-size: 32px;
      line-height: 40px;
    }
  }
  h4 {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    @include respond-to("s") {
      font-size: 28px;
      line-height: 35px;
    }
  }
  h5 {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    @include respond-to("s") {
      font-size: 24px;
      line-height: 30px;
    }
  }
  h6 {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    @include respond-to("s") {
      font-size: 22px;
      line-height: 28px;
    }
  }
  p {
    font-size: 16px;
    list-style: 24px;
    @include respond-to("s") {
      font-size: 22px;
      line-height: 33px;
    }
  }
  li {
    font-size: 16px;
    line-height: 24px;
    @include respond-to("s") {
      font-size: 22px;
      line-height: 33px;
    }
  }
  ul {
    li::before {
      height: 16px;
      width: 16px;
      background-size: 16px 16px;
      background-image: url("../../../../public/icons/ico-heart-orange.svg");
      background-repeat: no-repeat;
      @include respond-to("s") {
        height: 24px;
        width: 24px;
        background-size: 24px 24px;
      }
    }
  }
  ol {
    padding: 0;
    padding-left: 30px;
    @include respond-to("s") {
      padding-left: 38px;
    }
    li::marker {
      font-weight: bold;
    }
  }
  a {
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  hr {
    border: none;
    width: 100%;
    height: 4px;
    background-color: color(primary, primaryOne);
    margin: 0;
  }
}
