/**
* @license
* MyFonts Webfont Build ID 805996
*
* The fonts listed in this notice are subject to the End User License
* Agreement(s) entered into by the website owner. All other parties are
* explicitly restricted from using the Licensed Webfonts(s).
*
* You may obtain a valid license from one of MyFonts official sites.
* http://www.fonts.com
* http://www.myfonts.com
* http://www.linotype.com
*
*/

@font-face {
  font-family: "MuseoSansRounded";
  src: url("MuseoSansRounded300/font.woff2") format("woff2"),
    url("MuseoSansRounded300/font.woff") format("woff");
  font-weight: normal;
}
@font-face {
  font-family: "MuseoSansRounded";
  src: url("MuseoSansRounded700/font.woff2") format("woff2"),
    url("MuseoSansRounded700/font.woff") format("woff");
  font-weight: bold;
}
