.pageContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .header {
    position: fixed;
    top: 0;
    background-color: green;
    z-index: 2;
    width: 100%;
    overflow: hidden;
  }
  .contentContainer {
    z-index: 1;
    background-color: white;
  }
  .footer {
    overflow-y: scroll;
    position: fixed;
    bottom: 0;
  }
  .footer::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .footer {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
