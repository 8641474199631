@import "../../../../styles/breakpoints.scss";
@import "../../../../styles/palette.scss";

.modal {
  display: none;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;

  .backdrop {
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .closedrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .modalContent {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    transform: scale(0);
    transition: transform 0.3s ease-in;

    &.in {
      transform: scale(1);
    }

    @include respond-to("m") {
      width: 100%;
    }
    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .widthBox {
        display: flex;
        background-color: white;
        max-width: 800px;
        width: 100%;
        position: relative;
        margin: 0 25px;
        max-height: 80vh;
        @include respond-to("s") {
          margin: 0 80px;
        }
        .closeBtn {
          position: absolute;
          top: 0;
          right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          width: 40px;
          background-color: color(secondary, secondaryOne);
          border-radius: 0 0 24px 24px;
          cursor: pointer;
          svg {
            path:nth-child(1) {
              fill: none;
            }
            path:nth-child(2) {
              fill: color(primary, primaryOne);
            }
          }
          @include respond-to("s") {
            height: 48px;
            width: 48px;
          }
        }
        .children {
          overflow: scroll;
          margin: 50px 0 40px;
          @include respond-to("s") {
            margin: 80px 25px 66px;
          }
        }
      }
    }
  }

  @-webkit-keyframes zoom {
    from {
      -webkit-transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
    }
  }

  @keyframes zoom {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  @-webkit-keyframes zoomReverse {
    from {
      -webkit-transform: scale(1);
    }
    to {
      -webkit-transform: scale(0);
    }
  }

  @keyframes zoomReverse {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(0);
    }
  }
}
