@import url("../public/fonts/MuseoSansRounded/museoSansRounded.css");
@import "./breakpoints.scss";
@import "./palette.scss";

html,
body {
  padding: 0;
  margin: 0;
  font-family: MuseoSansRounded, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  overflow-x: clip;
}

iframe {
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.hover-dot {
  position: absolute;
  bottom: -7px;
  font-size: 32px;
  opacity: 0;
  transition: opacity 0.3s;
}

.smallText {
  font-size: 14px;
  line-height: 21px;
  @include respond-to("s") {
    font-size: 18px;
    line-height: 27px;
  }
}

.largeText {
  font-size: 20px;
  line-height: 25px;
  @include respond-to("s") {
    font-size: 32px;
    line-height: 40px;
  }
}

.label {
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  @include respond-to("s") {
    font-size: 22px;
    line-height: 28px;
  }
}

.jumboText {
  font-size: 40px;
  line-height: 44px;
  font-weight: bold;
  @include respond-to("s") {
    font-size: 72px;
    line-height: 80px;
  }
}

.unordered-list {
  list-style-type: none;
  padding: 0;
  li::before {
    margin-top: -2px;
    content: "";
    display: inline-block;
    vertical-align: middle;
    padding-right: 8px;
    @include respond-to("s") {
      margin-top: -5px;
      padding-right: 16px;
    }
  }
}

.heart-list {
  list-style-type: none;
  padding: 0;
  display: inline-block;
  li {
    float: left;
    padding-right: 16px;
    @include respond-to("s") {
      padding-right: 32px;
    }
  }
  li::before {
    margin-top: -2px;
    content: "";
    display: inline-block;
    vertical-align: middle;
    padding-right: 3px;
    @include respond-to("s") {
      margin-top: -5px;
    }
  }
}

.underline {
  display: table;
  cursor: pointer;
}

.underline:after {
  display: block;
  content: "";
  border-bottom: solid 2px;
  transform: scaleX(0);
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s;
  transform-origin: 0% 50%;
}

.underline:hover:after {
  transform: scaleX(1);
  opacity: 1;
}

.underlineActive {
  display: table;
  cursor: pointer;
}

.underlineActive:after {
  display: block;
  content: "";
  border-bottom: solid 2px;
  transform: scaleX(1);
  opacity: 1;
  transition: transform 0.3s ease-in-out, opacity 0.3s;
  transform-origin: 0% 50%;
}

.underlineActive:hover:after {
  transform: scaleX(0);
  opacity: 0;
}
.carousel {
  .carousel-status {
    display: none;
  }
  .control-dots {
    .dot {
      margin: 0 !important;
      margin-right: 8px !important;
      height: 16px !important;
      width: 16px !important;
      background: transparent !important;
      border: 1px solid color(secondary, secondaryOne);
    }
    .dot.selected {
      background: color(secondary, secondaryOne) !important;
    }
  }
}
