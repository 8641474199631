@import "../../../../../styles/breakpoints.scss";
@import "../../../../../styles/palette.scss";

.container {
  width: 100%;
  background-color: color(primary, primaryOne);
  // overflow-y: scroll;
  position: relative;
  bottom: 0;
  color: color(secondary, secondaryOne);
  .subContainer {
    background-color: color(secondary, secondaryOne);
    display: flex;
    justify-content: center;
    .widthBox {
      margin: 48px 25px;
      display: flex;
      max-width: 1600px;
      width: 100%;
      justify-content: center;
      @include respond-to("s") {
        justify-content: flex-end;
        margin: 96px 80px;
      }
      .row {
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 6px;
        border-bottom: 2px color(primary, primaryOne) solid;
        width: 100%;
        gap: 14px;
        @include respond-to("s") {
          width: 100%;
          padding-bottom: 20px;
        }
        @include respond-to("m") {
          width: 50%;
        }
        p {
          font-size: 20px;
          line-height: 25px;
          margin: 0;
          color: color(primary, primaryOne);
          @include respond-to("s") {
            font-size: 32px;
            line-height: 40px;
          }
        }
        .icon {
          margin-top: 8px;
          margin-right: 10px;
          transition: margin-right 0.3s ease-out;
          width: 24px;
          svg {
            g {
              path:nth-child(2) {
                fill: color(primary, primaryOne);
              }
            }
          }
        }
      }
      .row:hover {
        .icon {
          margin-right: 0px;
        }
      }
    }
  }
  .content {
    display: flex;
    justify-content: center;
    .widthBox {
      max-width: 1600px;
      width: 100%;
      margin: 56px 25px 40px 25px;
      @include respond-to("s") {
        margin: 110px 80px 40px 80px;
      }
      @include respond-to("l") {
        margin: 128px 80px 40px 80px;
      }
      .topContent {
        display: flex;
        flex-direction: column;
        gap: 40px;
        border-bottom: 8px solid color(secondary, secondaryOne);
        padding-bottom: 20px;
        margin-bottom: 48px;
        @include respond-to("m") {
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 96px;
        }
        .socialRow {
          display: flex;
          flex-direction: row;
          gap: 20px;
        }
        .imgWrapper {
          position: relative;
          height: 32px;
          width: 220px;
          @include respond-to("s") {
            width: 440px;
            height: 64px;
          }
        }
      }
      .middleContent {
        display: flex;
        flex-direction: column;
        border-bottom: 8px solid color(secondary, secondaryOne);
        padding-bottom: 48px;
        margin-bottom: 36px;
        @include respond-to("s") {
          padding-bottom: 128px;
        }
        @include respond-to("l") {
          flex-direction: row;
        }
        .mTopContent {
          display: flex;
          flex-direction: column;

          @include respond-to("l") {
            width: 50%;
            padding-right: 80px;
            margin-bottom: 0;
          }
          .mTopCol {
            margin-bottom: 52px;
            .richText {
              margin-bottom: 40px;
            }
            .logoRow {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              gap: 40px;
              .imgWrapper {
                height: 50px;
                position: relative;
                width: 100%;
              }
            }
          }
        }
        .mBottomContent {
          display: flex;
          flex-direction: column;
          gap: 40px;
          @include respond-to("m") {
            flex-direction: row;
          }
          @include respond-to("l") {
            width: 50%;
          }
          .header {
            color: color(secondary, secondaryOne);
            font-size: 12px;
            line-height: 16px;
            font-weight: bold;
            margin: 0;
            @include respond-to("s") {
              font-size: 16px;
              line-height: 24px;
            }
          }
          .mTop {
            display: flex;
            flex-direction: column;
            width: 100%;
          }
          .listCol {
            display: flex;
            flex-direction: column;
            width: 100%;
            .item {
              cursor: pointer;
              .listItem {
                padding: 12px 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                p {
                  margin: 0;
                  font-size: 14px;
                  line-height: 21px;
                  @include respond-to("s") {
                    font-size: 16px;
                    line-height: 24px;
                  }
                }
                .icon {
                  margin-right: 10px;
                  transition: margin-right 0.3s ease-out;
                }
              }
              hr {
                border: none;
                width: 100%;
                height: 2px;
                background-color: color(secondary, secondaryOne);
                margin: 0;
              }
            }

            .item:hover {
              .icon {
                margin-right: 0px;
              }
            }
            hr {
              border: none;
              width: 100%;
              height: 2px;
              background-color: color(secondary, secondaryOne);
              margin: 12px 0 0 0;
            }
          }
          hr {
            border: none;
            width: 100%;
            height: 2px;
            background-color: color(secondary, secondaryOne);
            margin: 12px 0;
          }
        }
      }
      .bottomContent {
        display: flex;
        align-items: center;
        flex-direction: column;
        @include respond-to("s") {
          flex-direction: row;
          justify-content: space-between;
        }
        .bRow {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          p {
            font-size: 12px;
            line-height: 14px;
            @include respond-to("s") {
              font-size: 14px;
              line-height: 16px;
            }
          }
          .dot {
            content: "";
            height: 8px;
            width: 8px;
            border-radius: 100%;
            background-color: color(secondary, secondaryOne);
          }
          .link {
            cursor: pointer;
            text-decoration: underline;
            font-size: 12px;
            line-height: 14px;
            @include respond-to("s") {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
        .btn {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 3px;
          border-radius: 50px;
          border: 1px solid color(secondary, secondaryOne);
          padding: 4px 6px;
          p {
            margin: 0;
            color: color(secondary, secondaryOne);
          }
          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              transform: scale(0.8);
            }
          }
        }
      }
    }
  }
}

.container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
