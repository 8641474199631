@import '../../../../../styles/breakpoints.scss';
@import '../../../../../styles/palette.scss';

.header {
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 56px;
  width: 100%;
  padding: 0 0 0 16px;

  @include respond-to('m') {
    height: 100px;
    padding: 0 22px;
  }

  .logo {
    position: relative;
    height: 36px;
    width: 76px;

    @include respond-to('m') {
      height: 55px;
      width: 116px;
    }
    .aLogo {
      cursor: pointer;
      position: absolute;
      width: 36px;
      height: 36px;
      transition: opacity 0.6s;
      opacity: 0;
      z-index: 10;

      @include respond-to('m') {
        width: 55px;
        height: 55px;
      }
    }
    .agilloLogo {
      cursor: pointer;
      position: absolute;
      top: 7px;
      left: 8px;
      width: 76px;
      height: 28px;
      transition: opacity 0.6s;
      opacity: 1;
      z-index: 11;

      @include respond-to('m') {
        top: 10px;
        left: 14px;
        width: 116px;
        height: 43px;
      }
    }
  }

  nav {
    display: none;
    flex-grow: 1;
    justify-content: center;

    @include respond-to('m') {
      display: flex;
    }

    .menuItems {
      display: flex;
      background-color: color(primary, primaryOne);
      color: color(secondary, secondaryOne);
      height: 56px;
      border-radius: 28px;
      margin: 0 15px;
      padding: 0;

      .menuItem {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 56px;
        display: flex;
        align-items: center;
        margin: 0 30px;
        cursor: pointer;

        .arrow {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;

          border-bottom: 5px solid white;
        }
      }
    }
  }

  .contactBtn {
    display: none;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: color(secondary, secondaryOne);
    height: 56px;
    padding: 0 32px;
    border-radius: 28px;
    font-size: 16px;
    color: color(primary, primaryOne);

    @include respond-to('m') {
      display: flex;
    }

    &:hover {
      cursor: pointer;

      :global(.hover-dot) {
        opacity: 1;
      }
    }
  }
}

.subMenu {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  color: color(secondary, secondaryOne) !important;
}

.linksWrapper {
  background-color: color(primary, primaryOne) !important;
  border-radius: 8px;
  padding: 8px 0 8px 6px;
  min-width: 220px;
}

.subMenuItem {
  background-color: transparent !important;
  padding: 0px 12px !important;
  height: 38px;
  width: 100%;
}

.subMenuItemText {
  display: flex;
  align-items: center;
  width: 100%;

  &::before {
    opacity: 0;
    transition: opacity 0.3s ease;
    content: '•';
    font-size: 32px;
    position: relative;
    left: -7px;
    top: -1px;
  }

  &:hover::before {
    opacity: 1;
  }
}

.myArrow {
  margin: 0 auto;
  width: 0;
  height: 0;
  margin-top: 5px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid color(primary, primaryOne);
}

.drawerBtn {
  position: fixed;
  right: 0;
  z-index: 12;
  margin-left: auto;
  width: 56px;
  background-color: color(secondary, secondaryOne);
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 0 28px;
  cursor: pointer;

  @include respond-to('m') {
    display: none;
  }

  .burger {
    svg {
      rect {
        fill: color(primary, primaryOne);
        transition: transform 0.3s ease-out;
      }
    }
  }

  .burger.isOpen {
    svg {
      rect {
        &:nth-of-type(1) {
          transform: rotate(45deg) translate(1px, -2px);
        }

        &:nth-of-type(2) {
          transform: rotate(45deg) translate(2px, -2px);
          opacity: 0;
        }

        &:nth-of-type(3) {
          transform: rotate(-45deg) translate(-10px, 9px);
        }
      }
    }
  }
}

.backdrop {
  display: none;
  position: fixed;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;

  @include respond-to('m') {
    display: none !important;
  }

  &.isOpen {
    display: block;
  }
}

.drawerMenu {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  transition: transform 0.25s ease-out;
  transform: translateX(100%);
  position: fixed;
  z-index: 11;
  top: 0;
  right: 0;
  background-color: color(primary, primaryOne);
  color: color(secondary, secondaryOne);
  height: 100%;
  width: 320px;
  max-width: 100vw;
  padding: 40px 10px 40px 40px;

  @include respond-to('m') {
    display: none;
  }

  .drawerLink {
    .rootItem {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 20px;
      cursor: pointer;
    }
    .subItem {
      cursor: pointer;
      font-size: 16px;
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }

  &.isOpen {
    transform: translateX(0);
  }
}
